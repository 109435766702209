{
  "$schema": "http://json.schemastore.org/package",
  "name": "@rrox/registry-auth",
  "private": true,
  "version": "1.0.0",
  "license": "MIT",
  "main": "dist/server/index.js",
  "files": [
    "dist"
  ],
  "scripts": {
    "build": "parcel build",
    "clean": "rimraf .parcel-cache/ dist/ package/ plugins/",
    "dev": "yarn update && yarn start",
    "fix": "yarn format && yarn typecheck",
    "format": "prettier --write \"**/*.{ts,js,css,scss,html,json}\"",
    "format:check": "prettier --check \"**/*.{ts,js,css,scss,html,json}\"",
    "start:dev": "node --inspect ../../node_modules/verdaccio/bin/verdaccio -c verdaccio.dev.yaml",
    "start": "verdaccio -c verdaccio.dev.yaml",
    "update": "yarn clean && yarn build && bash -c \"mkdir -p plugins/verdaccio-rrox-registry-auth && cp -R dist plugins/verdaccio-rrox-registry-auth && cp package.json plugins/verdaccio-rrox-registry-auth\"",
    "watch": "nodemon --watch src --ext ts,css --exec \"yarn update\""
  },
  "dependencies": {
    "@octokit/oauth-methods": "^1.2.6",
    "@octokit/request": "^5.6.3",
    "chalk": "^4.1.1",
    "discord-oauth2": "^2.10.0",
    "dotenv": "^16.0.0",
    "express": "^4.17.2",
    "global-agent": "^3.0.0",
    "lodash": "^4.17.21",
    "memory-cache": "^0.2.0",
    "minimist": "^1.2.0",
    "octokit": "^1.7.1",
    "open": "^8.0.7",
    "ow": "^0.28.1",
    "query-string": "^7.1.1"
  },
  "devDependencies": {
    "@parcel/config-default": "^2.2.1",
    "@parcel/transformer-typescript-tsc": "^2.2.1",
    "@types/express": "^4.17.9",
    "@types/global-agent": "^2.1.0",
    "@types/lodash": "^4.14.178",
    "@types/memory-cache": "^0.2.2",
    "@types/minimist": "^1.2.1",
    "@types/node": "^16.11.9",
    "@types/query-string": "^6.3.0",
    "@verdaccio/types": "^10.2.2",
    "c8": "^7.11.0",
    "core-js": "^3.21.0",
    "nodemon": "^2.0.6",
    "parcel": "^2.2.1",
    "prettier": "^2.5.1",
    "release-it": "^14.12.4",
    "timekeeper": "^2.2.0",
    "type-fest": "^2.11.1",
    "typescript": "^4.5.5",
    "verdaccio": "^5.6.2",
    "verdaccio-htpasswd": "^10.1.0",
    "vite": "^2.7.13",
    "vitest": "^0.2.7"
  },
  "peerDependencies": {
    "verdaccio": ">=5"
  },
  "engines": {
    "node": "^14||^16||^18||^19"
  },
  "publishConfig": {
    "registry": "https://registry.npmjs.org/"
  },
  "targets": {
    "client": {
      "source": "src/client/verdaccio-5.ts",
      "distDir": "dist/client",
      "context": "browser",
      "includeNodeModules": true
    },
    "server": {
      "source": "src/server/index.ts",
      "distDir": "dist/server",
      "context": "node",
      "outputFormat": "commonjs",
      "optimize": false,
      "isLibrary": true
    }
  }
}
